/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
.container {
  width: 100%;
  height: 100%;
  padding: 0px 80px;
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.coverContainer {
  width: 100%;
  height: 90vh;
  color: #fff;
  background-color: #ffffff;
  color: #161616;
  z-index: 0;
}
.coverContainer2 {
  width: 100%;
  height: 90vh;
  background-color: #ab4bff;
  color: #fff;
  z-index: 0;
  position: relative;
  border-radius: 0 0 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
}
.title2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  color: #f6f6f6;
  position: relative;
  animation: fadeIn 1s ease-in-out;
  padding: 0 20px;
  position: relative;
}
.title2 p {
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}
.title2 img {
  animation: slide 5s infinite ease-in-out;
}

@keyframes slide {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
}

.toolsCard {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  cursor: pointer;
}
.toolsCard img {
  border-radius: 100%;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  filter: drop-shadow(0 4px 3px rgba(26, 190, 95, 0.4))
    drop-shadow(0 2px 2px rgba(26, 190, 95, 0.3));
  transition: all 0.3s ease-in-out;
}
.toolsCard img:hover {
  transform: scale(0.9);
}
.toolsCard p {
  text-align: center;
  margin-top: 10px;
  font-size: 18px;
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
}
.toolsCard2 {
  cursor: pointer;
}
.toolsCard2 img {
  border-radius: 10%;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
}
.toolsCard2 img:hover {
  transform: scale(0.9);
}
.toolsCard2 p {
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #efefef;
}
.containerPink {
  width: 100%;
  height: 100%;
  padding: 0px 80px;
  background: #fffdf4;
  color: #000;
  display: flex;
  justify-content: center;
}

.containerBanner {
  width: 100%;
  height: 100%;
  padding: 0px 80px;
  background: #fe577c;
  color: #ffffff;
  display: flex;
  justify-content: center;
}

.containerBlackPadding {
  width: 100%;
  height: 100%;
  padding: 0px 80px;
  background: #0d0d0d;
  color: #f6f6f6;
  display: flex;
  justify-content: center;
}

.containerBlack {
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  color: #f6f6f6;
  display: flex;
  justify-content: center;
}

.backgroundContainerCover {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      0deg,
      rgba(17, 14, 14, 0.6) 100%,
      rgba(0, 0, 0, 0) 60%
    ),
    url("https://storage.googleapis.com/memshots-extras/memshots%20website/coverGif.gif")
      center center / cover no-repeat;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  border-radius: 24px;
  background: #bfcaff;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  flex-shrink: 0;
}
.card h3 {
  color: #141551;
  font-family: "Inter", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%; /* 26.04px */
  letter-spacing: -0.42px;
}
.card p {
  color: #141551;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  letter-spacing: -0.26px;
}
.card img {
  width: 100%;
  /* mix-blend-mode: darken; */
}

.closeIcon {
  animation: hamburgerFade 0.5s ease-in;
}

.hamburgerIcon {
  animation: hamburgerButton 0.5s ease-in;
}

@keyframes hamburgerButton {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.hamburger {
  position: fixed;
  top: 72px;
  left: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  border-radius: 0px 0px 16px 16px;
  background: #fff;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.16);
  animation: hamburgerFade 0.5s ease-in;
}

@keyframes hamburgerFade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.tryDemoButton {
  padding: 16px 36px;
  border-radius: 8px;
  /* background: #ab4bff; */
  border: 1px solid #33194a;
  color: #33194a;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.34px;
  cursor: pointer;
  transition: transform 1s ease;
}

.tryDemoButton:hover {
  transform: scale(1.07);
}

.buyNowButton {
  padding: 8px 24px;
  border-radius: 8px;
  background: #ab4bff;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.34px;
  cursor: pointer;
  transition: transform 1s ease;
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
    drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
}

.buyNowButton:hover {
  transform: scale(1.07);
}

.homeButtonBlack {
  padding: 12px 24px;
  color: #f6f6f6;
  border-radius: 40px;
  background: #2f2f2f;
  cursor: default;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.34px;
  text-wrap: nowrap;
}

.bannerDiv {
  width: 100%;
  max-width: 1440px;
  padding: 28px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 60px;
}

.pitchDiv {
  width: 100%;
  max-width: 1440px;
  padding: 80px 0;
}

.pitchTitle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  color: #f6f6f6;
  position: relative;
  animation: fadeIn 1s ease-in-out;
  padding: 0 20px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.pitchAbsolute {
  position: absolute;
  top: 45%;
  left: 67%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  transform: rotate(-8deg);
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #ffda08;
  color: #161616;
  font-family: "DM Serif Display";
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
  letter-spacing: -0.48px;
  white-space: nowrap;
  animation: bouncepitchAbsolute 1s infinite alternate; /* Apply the bounce animation */
}

@keyframes bouncepitchAbsolute {
  0% {
    transform: rotate(-8deg) scale(0.6); /* Start from original position */
  }
  100% {
    transform: rotate(-8deg) scale(1.1); /* Grow by 10% */
  }
}

.pitchScrollSection {
  margin-top: 60px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 12px;
}

.pitchScrollSection div {
  transition: transform 0.4s ease-in;
}

.pitchScrollSection div:hover {
  cursor: pointer;
  transform: scale(1.03);
}

.coverDiv {
  width: 100%;
  max-width: 1440px;
  margin-top: 62px;
}

.coverImageDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
}
.introToolsContainer {
  background: #13031d;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -60px;
  z-index: 1;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 35px 16px;
  width: 100%;
}

.introToolsContainer h3 {
  font-family: Inter;
  color: #efefef;
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.indroductionDiv {
  width: 100%;
  max-width: 1440px;
  margin: 80px 0px 0px 0;
}

.indroductionCover {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
}

.introProductsTitle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  gap: 24px;
  text-align: center;
}

.indroductionHeading {
  max-width: 706px;
}

.indroductionSubHeading {
  max-width: 496px;
  margin-top: 24px;
}

.customUrlDiv {
  width: 100%;
  max-width: 1440px;
  padding: 18px 0px 8px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.customUrlSection1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 26px 0px 32px 0px;
}

.customUrlSection1 p {
  max-width: 425px;
}

.customUrlSection2 {
  width: 50%;
  display: flex;
  justify-content: end;
}

.wishToolDiv {
  width: 100%;
  max-width: 1440px;
  margin: 80px 0px;
  display: flex;
  flex-direction: column;
  padding: 0 80px;
}

/* .wishToolDiv p {
  max-width: 504px;
} */

.wishToolPricing {
  margin-top: 40px;
}

.wishToolPricingSection {
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  gap: 60px;
  align-items: center;
}

.wishToolDemoDiv {
  width: 100%;
  max-width: 1440px;
  margin: 100px 0px 60px 0px;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.wishToolDemoContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
}

.wishToolDemoContainerSection2 {
  margin-right: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 0%;
}

.wishToolDemoContainerSection2 p {
  max-width: 302px;
}

.wishToolDemoAlign {
  display: flex;
  align-items: end;
  width: 100%;
}

.wishToolDemoAlign p {
  opacity: 0.3;
}

.photoShareToolDemoDiv {
  width: 100%;
  max-width: 1440px;
  position: relative;
  height: 864px;
}

.photoShareToolDemoContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 60px 0px;
  transform: translate(-50%, 0%);
  text-align: center;
}

.photoShareToolDemoContainerSection1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.photoShareToolDemoContainerSection1 p {
  max-width: 474px;
}

.photoShareToolDemoContainerSection2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.photoShareToolDemoContainerSection2 div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

.photoShareToolDemoContainerSection2 p {
  max-width: 280px;
}

/* .faceDetectionDiv {
  width: 100%;
  max-width: 1440px;
  margin: 120px 0px 60px 0px;
} */

.faceDetectionDiv {
  width: 100%;
  max-width: 1440px;
  margin: 60px 0px 60px 0px;
}

.faceDetectionTitle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  position: relative;
}

.faceDetectionTitle p {
  text-align: center;
  color: #161616;
}

.faceDetectionAbsolute {
  position: absolute;
  top: -30px;
  display: inline-flex;
  transform: rotate(-8deg);
  padding: 14px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #d2fd8c;
  color: #161616;
  font-family: "DM Serif Display";
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
  letter-spacing: -0.48px;
  white-space: nowrap;
  animation: bounceAlbum 1s infinite alternate; /* Apply the bounce animation */
}

@keyframes bounce {
  0% {
    transform: rotate(-8deg) translateY(0); /* Start from original position */
  }
  100% {
    transform: rotate(-8deg) translateY(-20px); /* Move up by 20px */
  }
}

.faceDetectionAbsolute img {
  width: 150px;
  height: 26px;
}

.faceDetectionContent {
  width: 100%;
  margin: 40px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 86px;
}

.faceDetectionCardSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 22px;
}

.faceDetectionCard {
  border-radius: 12px;
  padding: 35px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 240px;
  color: #ffffff;
  animation: slideIn 5s linear infinite;
}

@keyframes slideIn {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.albumAbsolute {
  position: absolute;
  top: -30px;
  display: inline-flex;
  transform: rotate(-8deg);
  padding: 14px 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #d2fd8c;
  color: #161616;
  font-family: "DM Serif Display";
  font-size: 24px;
  font-style: italic;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
  letter-spacing: -0.48px;
  white-space: nowrap;
  animation: bounceAlbum 1s infinite alternate; /* Apply the bounce animation */
}

@keyframes bounceAlbum {
  0% {
    transform: rotate(-8deg) scale(1); /* Start from original position */
  }
  100% {
    transform: rotate(-8deg) scale(1.1); /* Grow by 10% */
  }
}

.albumAbsolute img {
  width: 150px;
  height: 26px;
}

.albumButtonsSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.albumButtonsSection p {
  text-align: center;
  color: #161616;
}

.invitationDiv {
  width: 100%;
  max-width: 1440px;
  margin: 74px 0px 60px 0px;
}

.invitationSection {
  margin: 55px 0px 40px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.cardHeading {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 25.2px */
  letter-spacing: -0.42px;
  margin-top: 16px;
}

.cardSubHeading {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.52px;
  margin-top: 12px;
  opacity: 0.8;
  text-align: center;
}

.clientsDiv {
  width: 100%;
  max-width: 1440px;
  margin: 40px 0px 60px 0px;
}

.clientsScrollSection {
  width: 100%;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 20px;
  margin-top: 64px;
}

.clientsCards {
  width: 204px;
  flex-shrink: 0;
}

.clientsCards img {
  width: 100%;
  height: 84px;
  object-fit: contain;
}

.clientsCards p {
  margin-top: 12px;
  text-align: center;
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.52px;
  opacity: 0.8;
}

.eventsDiv {
  width: 100%;
  max-width: 1440px;
  margin: 60px 0px;
}

.eventsSection {
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 32px;
  row-gap: 27px;
}

.eventsCards {
  height: 218px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  border-radius: 8px;
  background: #f5f5f5;
  overflow: hidden;
}

.eventLogoSection {
  width: 180px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.eventLogoSection img {
  width: 140px;
  height: 100px;
  object-fit: contain;
}

.eventDetailSection {
  padding: 20px 24px 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.eventsUsers {
  margin-top: 16px;
  border-radius: 30px;
  background: #fff;
  padding: 8px 16px 6px 16px;
  color: #05156d;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.featuresDiv {
  width: 100%;
  max-width: 1440px;
  margin: 80px 0px 87px 0px;
}

.featuresDiv p {
  max-width: 460px;
}

.featuresSection {
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 87px;
  row-gap: 120px;
}

.howItWorksDiv {
  width: 100%;
  max-width: 1440px;
  margin: 78px 0px 33px 0px;
}

.HowItWorksContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 160px;
}

.HowItWorksContentSection2 {
  width: 100%;
  border-top: 1px solid #787878;
  border-bottom: 1px solid #787878;
  padding: 24px 0px;
}

.footerDiv {
  width: 100%;
  max-width: 1440px;
  margin: 40px 0px 20px 0px;
}

.footerContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 219px;
}

.footerContentSection2 {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  column-gap: 60px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.64px;
}

.footerContentSectionText {
  margin-top: 20px;
  color: #787878;
  cursor: pointer;
}

.footerDivSection2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.26px;
  color: #787878;
  margin-top: 40px;
}

@media (max-width: 768px) {
  .backgroundContainerCover {
    background-attachment: scroll;
  }
  .card {
    width: 90%;
  }
  .coverContainer2 {
    height: 80vh;
    border-radius: 0 0 20px 20px;
  }
  .introToolsContainer {
    max-width: 100%;
    border-radius: 12px;
  }
  .introToolsContainer h3 {
    font-size: 18px;
  }
  .toolsCard {
    flex-shrink: 0;
    max-width: 150px;
  }
  .toolsCard img {
    width: auto;
    height: 120px;
  }
  .toolsCard p {
    font-size: 11px;
  }
  .toolsCard2 p {
    font-size: 12px;
  }
  .toolsCard2 img {
    border-radius: 12px;
  }
  .tryDemoButton {
    padding: 12px 28px;
  }
}

@media (min-width: 639px) and (max-width: 1023px) {
  .container {
    padding: 0px 40px;
  }

  .containerPink {
    padding: 0px 40px;
  }

  .containerBanner {
    padding: 0px 40px;
  }

  .bannerDiv {
    gap: 40px;
  }

  .containerBlackPadding {
    padding: 0px 40px;
  }

  .indroductionHeading {
    max-width: 520px;
  }

  .indroductionSubHeading {
    margin-top: 20px;
  }

  .customUrlDiv {
    max-width: 1440px;
    flex-direction: column;
    padding: 40px 0px 20px 0px;
    gap: 67px;
  }

  .wishToolDiv {
    margin: 60px 0px;
    padding: 0;
  }

  .wishToolDemoDiv {
    gap: 46px;
  }

  .wishToolDemoContainerSection2 {
    margin-right: 40px;
    gap: 46px;
  }

  .featuresDiv {
    margin: 46px 0px 103px 0px;
  }

  .featuresDiv p {
    max-width: 311px;
  }

  .featuresSection {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 60px;
    row-gap: 60px;
  }

  .howItWorksDiv {
    margin: 45px 0px 58px 0px;
  }
}

@media (max-width: 639px) {
  .tryDemoButton:hover {
    transform: scale(1);
  }

  .buyNowButton:hover {
    transform: scale(1);
  }

  .container {
    padding: 0px 20px;
  }

  .containerPink {
    padding: 0px 20px;
  }

  .containerBanner {
    padding: 0px 20px;
  }

  .containerBlackPadding {
    padding: 0px 20px;
  }

  .bannerDiv {
    padding: 16px 0px;
    justify-content: space-between;
  }

  .coverDiv {
    margin-top: 31px;
  }

  .coverImageDiv {
    margin-top: 42px;
  }

  .indroductionSubHeading {
    margin-top: 16px;
  }

  .customUrlDiv {
    max-width: 1440px;
    flex-direction: column;
    padding: 30px 0px 15px 0px;
    gap: 30px;
  }

  .wishToolDiv {
    margin: 40px 0 0 0px;
    padding: 1.25rem;
    border-radius: 10px;
  }

  .wishToolDemoDiv {
    gap: 26px;
  }

  .wishToolDemoContainer {
    flex-direction: column;
    gap: 44px;
  }

  .wishToolDemoContainerSection2 {
    margin-right: 0;
    padding: 0px 20px;
    gap: 60px;
  }

  .photoShareToolDemoDiv {
    height: 480px;
  }

  .photoShareToolDemoContainer {
    padding: 40px 20px;
  }

  .photoShareToolDemoContainerSection2 {
    gap: 20px;
  }

  .photoShareToolDemoContainerSection2 div {
    gap: 20px;
  }

  .clientsDiv {
    margin: 30px 0px 42px 0px;
  }
  .clientsScrollSection {
    margin-top: 32px;
  }

  .clientsCards {
    width: 154px;
  }

  .featuresDiv {
    margin: 40px 0px;
  }

  .featuresSection {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 35px;
  }

  .featuresSection p {
    max-width: 281px;
  }

  .howItWorksDiv {
    margin: 40px 0px;
  }

  .footerContentSection2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 60px;
    row-gap: 50px;
  }
}

@media (max-width: 1023px) {
  .homeButtonBlack {
    padding: 6px 16px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 169.231% */
    letter-spacing: -0.26px;
  }

  .pitchDiv {
    padding: 60px 0;
  }

  .pitchTitle {
    gap: 40px;
  }

  .pitchAbsolute {
    padding: 10px 16px;
    font-size: 16px;
    line-height: 120%; /* 19.2px */
    letter-spacing: -0.32px;
    position: absolute;
    top: 32%;
    left: 62%;
  }

  .pitchScrollSection {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 20px;
    scrollbar-width: none;
    margin-top: 33px;
  }

  .pitchScrollSection div {
    flex-shrink: 0;
    max-width: 270px;
    transition: none;
  }

  .coverImageDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 70px;
  }

  .indroductionDiv {
    margin: 60px 0px 0px 0;
  }

  .indroductionCover {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
  }

  .introProductsTitle {
    margin-top: 60px;
    gap: 16px;
  }

  .customUrlSection1 {
    width: 100%;
    justify-content: flex-start;
    gap: 40px;
    padding: 0;
  }

  .customUrlSection2 {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .eventsDiv {
    margin: 40px 0px;
  }

  .eventsSection {
    margin-top: 40px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    row-gap: 20px;
  }

  .eventsCards {
    height: 144px;
  }

  .eventLogoSection {
    width: 120px;
    height: 100%;
  }

  .eventLogoSection img {
    width: 88px;
    height: 62px;
  }

  .eventDetailSection {
    padding: 16px 18px 16px 12px;
  }

  .eventsUsers {
    margin-top: 12px;
    padding: 6px 16px;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 160% */
  }

  /* .faceDetectionDiv {
    margin: 100px 0px 40px 0px;
  } */

  .faceDetectionDiv {
    margin: 40px 0px 40px 0px;
  }

  .faceDetectionTitle {
    gap: 24px;
  }

  .faceDetectionAbsolute {
    padding: 8px 16px;
  }

  .faceDetectionAbsolute img {
    width: 103px;
    height: 18px;
  }

  .faceDetectionContent {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  .faceDetectionCardSection {
    flex-direction: row;
    gap: 10px;
  }

  .faceDetectionCard {
    padding: 16px 17px;
    width: 100%;
    max-width: 170px;
    gap: 10px;
  }

  .cardHeading {
    font-size: 16px;
    letter-spacing: -0.32px;
  }

  .albumAbsolute {
    padding: 8px 16px;
  }

  .albumAbsolute img {
    width: 103px;
    height: 18px;
  }

  .albumButtonsSection {
    align-items: flex-start;
    gap: 20px;
  }

  .albumButtonsSection p {
    text-align: left;
  }

  .invitationDiv {
    margin: 80px 0px 40px 0px;
  }

  .InvitaionAbsolute {
    top: -40px;
    padding: 10px 16px;
  }

  .invitationSection {
    margin: 40px 0px 25px 0px;
  }

  .featuresSection {
    margin-top: 40px;
  }

  .HowItWorksContent {
    flex-direction: column;
    gap: 46px;
  }

  .wishToolPricingSection {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }

  .footerDiv {
    margin: 32px 0px 20px 0px;
  }

  .footerContent {
    flex-direction: column;
    gap: 60px;
  }

  .footerDivSection2 {
    margin-top: 32px;
  }
}

@media (min-width: 1370px) {
  .pitchAbsolute {
    position: absolute;
    left: 64%;
  }
}
