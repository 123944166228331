@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400..900&display=swap");

.container {
  border-radius: 24px;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.16);
}

.container button {
  padding: 7px 16px;
  border-radius: 6px;
}

.bgGradient {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fff 48.23%);
}

.cinzel700 {
  font-family: "Cinzel", serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 25.2px */
  letter-spacing: -0.42px;
}

.ToolContainer {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
  padding: 15px;
}

.buyNowButton {
  padding: 8px 24px;
  border-radius: 8px;
  background: #dee8fc;
  color: #3171f6;
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.34px;
}

.tryDemoButton {
  padding: 8px 24px;
  border-radius: 8px;
  background: #3171f6;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.34px;
}


