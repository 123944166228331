.signupPage {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  color: #000000;
  text-align: center;
}

.countinue {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  width: 100%;
  height: 52px;
  cursor: pointer;
  background: #ff6a99;
  backdrop-filter: blur(17px);
  border-radius: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  margin-top: 24px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.theGrtAbso {
  position: fixed;
  top: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  backdrop-filter: blur(40px);
  /* min-height: 100vh; */
  overflow-y: auto;
}

.absol {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -20px);
  width: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 20px 0 20px;
  margin-bottom: 32px;
}

.atext {
  margin-top: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #787878;
}

.signGoog {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 32px;
  gap: 12px;
  width: 100%;
  height: 52px;
  background: #014768;
  backdrop-filter: blur(17px);
  border-radius: 25px;
  cursor: pointer;
}

.signGoog>p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}



.mobileLogin input {
  width: 100%;
  height: 52px;
  background: #FFF;
  border-radius: 25px;
  border: none;
  padding: 13px;
  border: 1px solid #DB504A;
}

.mobileLogin input[type="checkbox"] {
  width: 24px;
  height: 24px;
  accent-color: #00a989;

  color: #ffffff;
}

.phoneInput {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.phoneInput input {
  border: none;
  margin: 0;
  padding-right: 0;
  font-size: 16px;
  width: 100%;
  border-radius: 0 8px 8px 0;
}

.phoneInput input:first-child {
  width: 20%;
  border-radius: 8px 0 0 8px;
}

.button {
  width: 100%;
  padding: 16px 0;
  border-radius: 25px;
  color: #ffffff;
}

.button[disabled] {
  opacity: 60%;
  cursor: not-allowed;
}