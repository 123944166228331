.containerBlackPadding {
  width: 100%;
  height: 100%;
  padding: 0px 80px;
  background: #0d0d0d;
  color: #f6f6f6;
  display: flex;
  justify-content: center;
}

.footerDiv {
  width: 100%;
  max-width: 1440px;
  margin: 40px 0px 20px 0px;
}

.popup {
  align-items: center;
  background-color: #000;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 50%;
  padding: 100px;
  position: fixed;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 1111000000;
}

.theimgFace {
  max-height: 70vh;
  max-width: 70%;
  object-fit: cover;
  position: absolute;
  object-position: top;
}

.next {
  position: absolute;
  cursor: pointer;
  display: block;
  left: 8.47%;
  top: 50%;
  bottom: 6.25%;
  border-radius: 50px;
}

.previous {
  position: absolute;
  right: 8.47%;
  display: block;
  top: 50%;
  bottom: 6.25%;
  cursor: pointer;
  border-radius: 50px;
}

@media only screen and (max-width: 480px) {
  .popup {
    padding: 0px;
  }

  .theimgFace {
    max-width: 100%;
    max-height: 70vh;
    width: 100%;
  }

  .next {
    position: absolute;
    cursor: pointer;
    z-index: 10000;
    border-radius: 50px;
    bottom: 10px;
    left: 20px;
    top: unset;
  }

  .previous {
    position: absolute;
    cursor: pointer;
    z-index: 10000;
    border-radius: 50px;
    bottom: 10px;
    right: 20px;
    top: unset;
  }
}

@media (min-width: 640px) and (max-width: 1024px) {
  .containerBlackPadding {
    padding: 0px 40px;
  }
}

@media (max-width: 640px) {
  .containerBlackPadding {
    padding: 0px 20px;
  }
}

@media (max-width: 1024px) {
  .footerDiv {
    margin: 32px 0px 20px 0px;
  }
}
