.AdvancedToolsHead {
  color: #161616;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 85.714% */
}

.toolsText {
  color: #fff;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 39.2px */
}
.toolsPara {
  color: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
}
.downloadAppText {
  color: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 18.2px */
}

.downloadButton {
  display: inline-flex;
  padding: 8px 24px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  color: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 169.231% */
  letter-spacing: -0.26px;
  background: #000;
}
.toolsMainCard {
  display: flex;
  width: 100%;
  padding-bottom: 12px;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1);
}

.welcome {
  color: #161616;
  text-align: center;

  /* Text lg / Semibold */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
}
.welcometext {
  color: #787878;
  text-align: center;

  /* Text sm / Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.playBut {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  /* background: #ebe9e9; */
}
.turtorials {
  color: #161616;
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 114.286% */
}
