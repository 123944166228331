.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 0px 80px;
  background: #fff;
  color: #000;
  min-height: 90vh;
}

.profilePicture {
  width: 82px;
  height: 82px;
  border-radius: 100%;
}

.signOutButton {
  color: #ff5077;
  text-align: right;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.64px;
  cursor: pointer;
}

.tabs {
  width: 100%;
  background: white;
  margin-top: 32px;
  margin-bottom: 16px;
}

.tabsHeader {
  display: flex;
  flex-direction: row;
  gap: 24px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.tabsFilter {
  display: inline-flex;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 36px;
  background: #f4f5f6;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
  flex-shrink: 0;
  cursor: pointer;
}

.ownCard {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fbfcfd;
  padding: 16px 16px 20px 16px;
}

.createdByButton {
  border-radius: 30px;
  background: #f4f5f6;
  padding: 8px 16px;
  width: fit-content;
}

.ownCardSection {
  width: 100%;
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 24px;
}

.ownCardInner {
  border-radius: 8px;
  background: #f4f5f6;
  padding: 16px 18px 16px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 18px;
  cursor: pointer;
  align-items: center;
}

.selectedPackageInd {
  display: inline-flex;
  padding: 2px 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  color: #fff;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px; /* 130% */
  letter-spacing: -0.2px;
  margin-left: 20px;
}

.upgradeplanButton {
  display: inline-flex;
  padding: 2px 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #3171f6;
  color: #fff;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px; /* 130% */
  letter-spacing: -0.2px;
}

.retryPayment {
  display: inline-flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 7px;
  background: #e34d4d;
  color: #fff;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.26px;
  cursor: pointer;
}

.goHomeButton {
  padding: 8px 16px 8px 16px;
  border-radius: 7px;
  opacity: 0px;
  background: #3171f6;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #ffffff;
  margin-top: 16px;
  cursor: pointer;
}

@media (max-width: 1023px) {
  .container {
    padding: 0px 40px;
  }

  .ownCardSection {
    margin-top: 16px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 16px;
  }
}

@media (max-width: 639px) {
  .container {
    padding: 0px 20px;
  }

  .profilePicture {
    width: 62px;
    height: 62px;
  }

  .tabs {
    margin-top: 32px;
    margin-bottom: 20px;
  }

  .tabsHeader {
    gap: 16px;
  }

  .ownCard {
    padding: 16px 16px;
  }
}
