.card {
  border-radius: 24px;
  background: #bfcaff;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  flex-shrink: 0;
  max-width: 250px;
  border: 1px solid #f1f1f1;
}
.card h3 {
  color: #141551;
  font-family: "Inter", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%; /* 26.04px */
  letter-spacing: -0.42px;
}
.card p {
  color: #141551;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  letter-spacing: -0.26px;
}
.card img {
  width: 100%;
  /* mix-blend-mode: darken; */
}
