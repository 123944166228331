@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@1&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    
  }

  @font-face {
    font-family: 'ClashDisplay-Semibold';
    src: url('https://eglfonts.s3.ap-south-1.amazonaws.com/ClashDisplay/ClashDisplay-Semibold.woff2') format('woff2'),
         url('https://eglfonts.s3.ap-south-1.amazonaws.com/ClashDisplay/ClashDisplay-Semibold.woff') format('woff'),
         url('https://eglfonts.s3.ap-south-1.amazonaws.com/ClashDisplay/ClashDisplay-Semibold.ttf') format('truetype');
         font-weight: 600;
         font-display: swap;
         font-style: normal;
  }
  
  
  @font-face {
    font-family: 'ClashDisplay-Bold';
    src: url('https://eglfonts.s3.ap-south-1.amazonaws.com/ClashDisplay/ClashDisplay-Bold.woff2') format('woff2'),
         url('https://eglfonts.s3.ap-south-1.amazonaws.com/ClashDisplay/ClashDisplay-Bold.woff') format('woff'),
         url('https://eglfonts.s3.ap-south-1.amazonaws.com/ClashDisplay/ClashDisplay-Bold.ttf') format('truetype');
         font-weight: 700;
         font-display: swap;
         font-style: normal;
  }