.parent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.header {
  width: 100%;
  height: 100%;
  padding: 50px 20px 84px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f3f3f3;
  text-align: center;
}

.pricingPlansDiv {
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: #0c0a2a;
  color: #f7f7f7;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}

.header .p1 {
  margin-top: 24px;
  color: #161616;
  text-align: center;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 124%; /* 49.6px */
  letter-spacing: -0.8px;
  max-width: 700px;
}

.header .p2 {
  margin-top: 24px;
  color: #161616;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
}

.pricingCard {
  border-radius: 16px;
  height: 100%;
  width: 280px;
  text-align: left;
}

.pricingCardShort {
  border-radius: 16px;
  height: 100%;
  width: 610px;
  text-align: left;
}

.recommendedDiv {
  display: inline-flex;
  padding: 5px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #fff;
  color: #f6f6f6;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 220% */
  letter-spacing: -0.2px;
}

.banner {
  width: 100%;
  height: 100%;
  max-width: 941px;
  border-radius: 16px;
  overflow: hidden;
  background: #fff;
  padding: 20px 30px;
  position: relative;
  margin-top: 36px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
}

.banner .absoluteBlue {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  max-width: 369px;
  height: 100%;
  background: linear-gradient(
    90deg,
    #3171f6 0.04%,
    rgba(49, 113, 246, 0) 99.76%
  );
}

.contactUsButton {
  display: flex;
  width: 178px;
  height: fit-content;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.26px;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
}

.contactUsButton:hover {
  transform: scale(1.05);
}

.filterChip {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  border: 2px solid #e5e5e5;
  color: #161616;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
  cursor: pointer;
  flex-shrink: 0;
}

.filterChip:hover {
  border: 2px solid #3171f6;
  color: #3171f6;
}

.singleEventHeader {
  width: 100%;
  height: 100%;
  padding: 60px 20px 24px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #161616;
}

.button {
  display: flex;
  width: 152px;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
}

.button:hover {
  transform: scale(1.05);
}

.packageFilterSection {
  display: inline-flex;
  padding: 4px;
  align-items: flex-start;
  gap: 2px;
  border-radius: 40px;
  background: #fff;
}

.packageFilterSection button {
  display: flex;
  width: 158px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.32px;
}

@media (max-width: 1024px) {
  .header {
    padding: 15px 20px 64px 20px;
  }

  .pricingPlansDiv {
    display: inline-flex;
    padding: 14px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #f7f7f7;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 18.2px */
    letter-spacing: -0.26px;
  }

  .header .p1 {
    margin-top: 16px;
    color: #161616;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 124%; /* 29.76px */
    letter-spacing: -0.48px;
  }

  .header .p2 {
    margin-top: 24px;
    max-width: 256px;
  }

  .pricingCard {
    border-radius: 16px;
    height: 100%;
    width: 100%;
    min-width: 280px;
    max-width: 350px;
    text-align: left;
  }

  .pricingCardShort {
    border-radius: 16px;
    height: 100%;
    max-width: 280px;
    text-align: left;
  }

  .banner {
    width: 100%;
    max-width: 350px;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
    background: #fff;
    padding: 15px;
    position: relative;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    z-index: 0;
    text-align: left;
  }

  .banner .absoluteBlue {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    max-height: 72px;
    background: linear-gradient(
      180deg,
      #3171f6 0.04%,
      rgba(49, 113, 246, 0) 99.76%
    );
  }
}
