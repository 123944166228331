.container {
  width: 100%;
  height: 100%;
  padding: 0px 80px;
  background: #fff;
  color: #000;
  display: flex;
  justify-content: center;
}

.containerColor {
  width: 100%;
  height: 100%;
  padding: 0px 80px;
  background: #f4faff;
  color: #000;
  display: flex;
  justify-content: center;
}

.coverDiv {
  width: 100%;
  max-width: 1440px;
  margin-top: 82px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.coverDiv p {
  max-width: 564px;
  margin-top: 16px;
  color: #161616;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.64px;
  opacity: 0.6;
}

.coverImageDiv {
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
}

.testimonialsDiv {
  width: 100%;
  max-width: 1440px;
  margin-top: 60px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}

.testimonialsContainer {
  margin-top: 70px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 70px;
}

.testimonialsCards {
  filter: drop-shadow(0px 4px 32px rgba(151, 71, 255, 0.16));
  border-radius: 12px;
  background: #f8f8f8;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
  align-items: center;
  cursor: pointer;
}

.cardsMark {
  height: 100%;
  width: 12px;
  flex-shrink: 0;
  opacity: 0.8;
  background-color: #a8c2f7;
  transition: background-color 0.3s ease;
}

.cardsContent {
  position: relative;
  padding: 24px 46px 24px 4px;
  gap: 12px;
  display: flex;
  align-items: center;
}

.cardsContent svg {
  position: absolute;
  top: 24px;
  right: 12px;
  opacity: 0.4;
  width: 32px;
  height: 32px;
  transition: opacity 0.3s ease;
}

.testimonialsCards:hover .cardsMark {
  background-color: #9747ff;
}

.testimonialsCards:hover svg {
  opacity: 0.8;
}

.tryDemoButton {
  padding: 8px 24px;
  border-radius: 8px;
  background: #3171f6;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.34px;
  cursor: pointer;
  width: fit-content;
  transition: transform 1s ease;
}

.tryDemoButton:hover {
  transform: scale(1.07);
}

.buyNowButton {
  padding: 8px 24px;
  border-radius: 8px;
  background: #dee8fc;
  color: #3171f6;
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.34px;
  cursor: pointer;
  transition: transform 1s ease;
}

.buyNowButton:hover {
  transform: scale(1.07);
}

.productsDiv {
  width: 100%;
  height: 100%;
  max-width: 1440px;
}

.productCards {
  margin: 60px 0px;
  display: flex;
  flex-direction: row;
  gap: 118px;
  align-items: center;
}

.productCards img {
  width: 100%;
  max-width: 374px;
  object-fit: cover;
}

.productsGridContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 24px;
}

.productsGridContainer li {
  padding: 10px 24px;
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
  white-space: nowrap;
}

.featuresDiv {
  width: 100%;
  height: 100%;
  max-width: 1440px;
  margin-top: 52px;
  margin-bottom: 60px;
}

.featuresDiv ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
  margin-top: 50px;
}

.featuresDiv ul li {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 50px;
}

@media (min-width: 640px) and (max-width: 1024px) {
  .container {
    padding: 0px 40px;
  }

  .containerColor {
    padding: 0px 40px;
  }
}

@media (max-width: 640px) {
  .container {
    padding: 0px 20px;
  }

  .containerColor {
    padding: 0px 20px;
  }

  .coverDiv {
    margin-top: 31px;
    margin-bottom: 46px;
  }

  .coverDiv p {
    margin-top: 30px;
  }

  .testimonialsDiv {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .tryDemoButton:hover {
    transform: scale(1);
  }

  .buyNowButton:hover {
    transform: scale(1);
  }
}

@media (max-width: 1024px) {
  .coverImageDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 36px;
  }

  .testimonialsContainer {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .cardsContent {
    position: relative;
    padding: 20px 34px 20px 8px;
    gap: 9px;
  }

  .cardsContent svg {
    top: 20px;
    right: 12px;
    width: 24px;
    height: 24px;
  }

  .cardsMark {
    width: 8px;
  }

  .productCards {
    margin: 40px 0px;
    display: flex;
    flex-direction: column-reverse;
    gap: 40px;
  }

  .productsGridContainer {
    gap: 12px;
  }

  .productsGridContainer li {
    padding: 10px 16px;
    color: #fff;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 138.462% */
    letter-spacing: -0.26px;
  }

  .featuresDiv {
    width: 100%;
    height: 100%;
    max-width: 1440px;
    margin-top: 38px;
    margin-bottom: 30px;
  }
  .featuresDiv ul {
    margin-top: 30px;
    gap: 40px;
  }

  .featuresDiv ul li {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
