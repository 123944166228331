.container {
  width: 100%;
  height: 100%;
  padding: 0px 80px;
  background: #fff;
  color: #000;
  display: flex;
  justify-content: center;
}

.mainDiv {
  width: 100%;
  max-width: 1440px;
  margin-top: 62px;
  margin-bottom: 62px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  gap: 25px;
}

.childDiv1 {
  flex: 1 1 0%;
}

.noteDiv {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 16px 15px;
  position: relative;
  margin-top: 22px;
  max-width: 441px;
}

.noteDiv div {
  padding: 2px 7px;
  border-radius: 4px;
  background: #3171f6;
  width: fit-content;
  position: absolute;
  top: -10%;
}

.stepsDiv {
  margin-top: 22px;
  display: flex;
  gap: 16px;
  overflow-x: auto;
  overflow-y: hidden;
}

.item1 {
  background: #eed5fd;
  border-radius: 16px;
  padding: 16px;
  flex-shrink: 0;
  max-width: 210px;
}
.item1 p {
  max-width: 267px;
}

.item2 {
  background: #d6d5fd;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  max-width: 210px;
}

.item3 {
  background: #f9ffd7;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  max-width: 210px;
}

.item4 {
  background: #284535;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  max-width: 350px;
}

.item4:hover {
  opacity: 0.8;
}

.item4 div {
  position: absolute;
  display: flex;
  gap: 9px;
  left: 16px;
  bottom: 16px;
  color: #fff;
}

.childDiv2 {
  display: flex;
  justify-content: end;
  flex: 1 1 0%;
}

.startDemoButton {
  padding: 10px 18px;
  border-radius: 40px;
  background: #3171f6;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.34px;
  text-wrap: nowrap;
  height: fit-content;
  flex-shrink: 0;
}

@media (min-width: 640px) and (max-width: 1023px) {
  .container {
    padding: 0px 40px;
  }
}

@media (max-width: 639px) {
  .container {
    padding: 0px 20px;
  }
  .mainDiv {
    margin-top: 31px;
    margin-bottom: 31px;
  }
}

@media (max-width: 1023px) {
  .mainDiv {
    /* grid-template-columns: 1fr; */
    flex-direction: column;
    gap: 44px;
  }

  .childDiv2 {
    justify-content: center;
  }

  .absoluteDiv {
    position: absolute;
    margin: 16px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    top: 0;
  }

  .noteDiv {
    margin-top: 24px;
  }
}

@media (min-width: 1023px) {
  .absoluteDiv {
    position: absolute;
    margin: 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 8px;
    bottom: 0;
  }

  .stepsDiv {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
    column-gap: 20px;
    row-gap: 22px;
  }

  .item1 {
    background: #eed5fd;
    border-radius: 16px;
    padding: 16px;
    max-width: 500px;
    grid-row: 1;
    grid-column: span 2 / span 2;
  }
  .item1 p {
    max-width: 267px;
  }

  .item2 {
    background: #d6d5fd;
    border-radius: 16px;
    padding: 16px;
    grid-row: 1;
    grid-column: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .item3 {
    background: #f9ffd7;
    border-radius: 16px;
    padding: 16px;
    grid-row: 2;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .item4 {
    background: #284535;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    max-width: 500px;
    grid-row: 2;
    grid-column: span 2 / span 2;
    cursor: pointer;
  }

  .item4:hover {
    opacity: 0.8;
  }

  .item4 div {
    position: absolute;
    display: flex;
    gap: 9px;
    left: 16px;
    bottom: 16px;
    color: #fff;
  }
}
